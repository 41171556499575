import React, { Component } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { Link } from "react-router-dom";

class HomeBanner extends Component {
  state = {};
  constructor() {
    super();
    this.counter1 = (
      <CountUp start={1} end={1105} duration={4} prefix="+">
        {({ countUpRef, start }) => (
          <VisibilitySensor onChange={start} delayedCall>
            <span ref={countUpRef} />
          </VisibilitySensor>
        )}
      </CountUp>
    );
    this.counter2 = (
      <CountUp start={1} end={65} duration={4} suffix="%">
        {({ countUpRef, start }) => (
          <VisibilitySensor onChange={start} delayedCall>
            <span ref={countUpRef} />
          </VisibilitySensor>
        )}
      </CountUp>
    );
    this.counter3 = (
      <CountUp start={1} end={35} duration={4} prefix="+" suffix="K">
        {({ countUpRef, start }) => (
          <VisibilitySensor onChange={start} delayedCall>
            <span ref={countUpRef} />
          </VisibilitySensor>
        )}
      </CountUp>
    );
  }

  render() {
    return (
      <div>
        <section className="section-1 for-desktop">
          <div className="container">
            <div className="flex-row">
              <div className="column-1">
                {/* <p>
                In Partnership with{" "}
                <a
                  href="https://uniglobalinitiative.org"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src="images/UGI.png"
                    alt="unitedglobal-logo"
                  />{" "}
                </a>{" "}
                and{" "}
                <a
                  href="https://www.womenintechpk.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src="images/WITPK.png"
                    alt="womeinintech-logo"
                  />
                </a>{" "}
              </p> */}
                <h2>
                  empowering <span>women</span>
                </h2>
                <p className="para-2">
                  Through Vocational IT &amp; Business Training
                </p>
                <div className="number-counter">
                  <div className="counter-status">
                    <p>Total Trainings</p>
                    <p className="para-2">{this.counter1}</p>
                  </div>
                  <div className="counter-partition">
                    <p>|</p>
                  </div>
                  <div className="counter-status">
                    <p>Placement Rate</p>
                    <p className="para-2">{this.counter2}</p>
                  </div>
                  <div className="counter-partition">
                    <p>|</p>
                  </div>
                  <div className="counter-status">
                    <p>Starting Salary</p>
                    <p className="para-2">{this.counter3}</p>
                  </div>
                </div>

                {/* <div className="images-banner">
                <img src="/images/image1.svg" alt="No Poverty"/>
                <img src="/images/image2.svg" alt="Quality Education"/>
                <img src="/images/image3.svg" alt="Gender Equality"/>
              </div> */}
                <div className="locations-heading">
                  {/* <h3>OUR LOCATIONS</h3> */}
                  <div className="locations">
                    <p>
                      <Link to="/become-a-codegirl">Karachi</Link>
                    </p>
                    <p>
                      <Link to="/become-a-codegirl">Skardu</Link>
                    </p>
                    <p>
                      <Link to="/become-a-codegirl-south-africa">South Africa</Link>
                    </p>
                  </div>
                </div>
                <div className="images-banner">
                  <img src="/images/NoPoverty.svg" alt="No Poverty" />
                  <img
                    src="/images/QualityEducation.svg"
                    alt="Quality Education"
                  />
                  <img src="/images/GenderEquality.svg" alt="Gender Equality" />
                  <img src="/images/DecentWork.svg" alt="DecentWork" />
                  <img src="/images/Partnerships.svg" alt="Partnerships" />
                </div>
              </div>
              <div className="column-1 diam-column">
                <img src="images/yellowdots_03.png" alt="yellowdots" />
                <div className="diamond diamond-small" />
              </div>
              <div className="column-3 diam-column2">
                <div className="diamond diamond-large" />
                <div className="diamond diamond-medium" />
                <div className="diamond diamond-small" />
              </div>
            </div>
            <div className="united_nation_para">
              <p>
                Working towards these United Nations Sustainable Development
                Goals (SDGs)
              </p>
            </div>
            <div className="home-partners-section desktop_home">
              <p>
                <span>A project by</span>{" "}
                <img src="/images/consulnet.svg" alt="consulnet" />
                <span>in partnership with</span>
                <img src="/images/united-global.svg" alt="united-global" />{" "}
                <span>&</span>{" "}
                <img src="/images/womenintech.svg" alt="womenintech" />
              </p>
            </div>
            <div className="home-partners-section mobile_home_partners">
              <p>
                <span>A project by</span>
                <img src="/images/consulnet.svg" alt="consulnet" />
                <br />
                <span>in partnership with</span>
                <br />
                <img src="/images/united-global.svg" alt="united-global" />
                <br />
                <span>&</span>
                <br />
                <img src="/images/womenintech.svg" alt="womenintech" />
              </p>
            </div>
          </div>
        </section>
        <section className="section-1 for-mobile">
          <div className="container">
            <div className="flex-row">
              <div className="column-1">
                {/* <p>
                  In Partnership with{" "}
                  <a
                    href="https://uniglobalinitiative.org"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img
                      src="images/UGI.png"
                      alt="unitedglobal-logo"
                    />{" "}
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://www.womenintechpk.com"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img
                      src="images/WITPK.png"
                      alt="womeinintech-logo"
                    />
                  </a>{" "}
                </p> */}
                <h2>
                  empowering <span>women</span>
                </h2>
                <p className="para-2">
                  Through Vocational IT &amp; Business Training
                </p>
                <div className="number-counter">
                  <div className="counter-status">
                    <p>Total Trainings</p>
                    <p className="para-2">{this.counter1}</p>
                  </div>
                  <div className="counter-partition">
                    <p>|</p>
                  </div>
                  <div className="counter-status">
                    <p>Placement Rate</p>
                    <p className="para-2">{this.counter2}</p>
                  </div>
                  <div className="counter-partition">
                    <p>|</p>
                  </div>
                  <div className="counter-status">
                    <p>Starting Salary</p>
                    <p className="para-2">{this.counter3}</p>
                  </div>
                </div>

                <div className="locations-heading">
                  {/* <h3>OUR LOCATIONS</h3> */}
                  <div className="locations">
                    <p>
                      <Link to="/become-a-codegirl">Karachi</Link>
                    </p>
                    <p>
                      <Link to="/become-a-codegirl">Skardu</Link>
                    </p>
                    <p>
                      <Link to="/become-a-codegirl-south-africa">South Africa</Link>
                    </p>
                  </div>
                </div>
                <div className="images-banner">
                  <img src="/images/NoPoverty.svg" alt="No Poverty" />
                  <img
                    src="/images/QualityEducation.svg"
                    alt="Quality Education"
                  />
                  <img src="/images/GenderEquality.svg" alt="Gender Equality" />
                  <img src="/images/DecentWork.svg" alt="DecentWork" />
                  <img src="/images/Partnerships.svg" alt="Partnerships" />
                </div>

                {/* <div className="images-banner">
                  <img src="/images/image1.svg" alt="No Poverty" />
                  <img src="/images/image2.svg" alt="Quality Education" />
                  <img src="/images/image3.svg" alt="Gender Equality" />
                </div> */}
              </div>
              <div className="united_nation_para">
                <p>
                  Working towards these United Nations Sustainable Development
                  Goals (SDGs)
                </p>
              </div>
              <div className="column-1 diam-column">
                <img src="images/yellowdots_03.png" alt="yellowdots" />
                <div className="diamond diamond-small" />
              </div>
              <div className="column-3 diam-column2">
                <div className="diamond diamond-large" />
                <div className="diamond diamond-medium" />
                <div className="diamond diamond-small" />
              </div>
            </div>
            <div className="home-partners-section desktop_home">
              <p>
                <span>A project by</span>{" "}
                <img src="/images/consulnet.svg" alt="consulnet" />
                <span>in partnership with</span>
                <img src="/images/united-global.svg" alt="united-global" />{" "}
                <span>&</span>{" "}
                <img src="/images/womenintech.svg" alt="womenintech" />
              </p>
            </div>
            <div className="home-partners-section mobile_home_partners">
              <p>
                <span>A project by</span>
                <img src="/images/consulnet.svg" alt="consulnet" />
                <br />
                <span>in partnership with</span>
                <br />
                <img src="/images/united-global.svg" alt="united-global" />
                <br />
                <span>&</span>
                <br />
                <img src="/images/womenintech.svg" alt="womenintech" />
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default HomeBanner;
